import React, { useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { unsubscribeUser } from "../services/services";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit">FLYCHEERGEAR</Link> {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    height: "30%",
    width: "30%",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "black",
  },
}));

export default function Unsubscribe() {
  const classes = useStyles();
  const [email, setEmail] = React.useState("");

  // useEffect(() => {
  //   const unsubscribeUserAsync = async () => {
  //     // const tkn = await getToken({ oAuth, instanceId, closeWindowBool });
  //   };
  //   unsubscribeUserAsync();
  // }, []);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const unsubscribeUserBtn = async (event) => {
    event.preventDefault();
    let mail = email.trim();
    if (mail.length === 0) {
      window.alert("Email cannot be empty");
      return;
    }

    const unsubscribeUserData = await unsubscribeUser({
      email: mail,
      unsubscribed: true,
    });
    if (unsubscribeUserData && unsubscribeUserData.status === "success") {
      window.alert("Unsubscribed Successfully!");
    } else {
      window.alert("Could not find email!");
    }
  };

  return (
    <div
      style={{
        // backgroundImage: `url(${b})` ,
        height: "100%",
        position: "absolute",
        left: "0",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar
            src={process.env.PUBLIC_URL + "logo.png"}
            className={classes.avatar}
          ></Avatar>
          <Typography component="h1" variant="h5">
            Enter Email to Unsubscribe
          </Typography>
          <form className={classes.form} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  value={email}
                  onChange={handleEmailChange}
                  label="Email Address"
                  name="email"
                  type="email"
                  autoComplete="email"
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={unsubscribeUserBtn}
            >
              Unsubscribe
            </Button>
          </form>
        </div>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Container>
    </div>
  );
}
