import React, { useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Backdrop from "@material-ui/core/Backdrop";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
// import b from './backgroundFront.jpg'
import { useHistory } from "react-router-dom";
import { getToken } from "../services/services";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit">FLYCHEERGEAR</Link> {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    height: "30%",
    width: "30%",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "black",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function SignIn(props) {
  const [tokenAccess, setAccessToken] = React.useState("");
  const [tokenRefresh, setRefreshToken] = React.useState("");
  const [loaderBool, setLoaderBool] = React.useState(true);
  const [errText, setErrText] = React.useState(false);
  const [open, setOpen] = React.useState(true);
  const classes = useStyles();
  const history = useHistory();
  const handleSubmit = () => {
    history.push({
      pathname: "/main",
      state: {
        // response: messageFromServer
      },
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setLoaderBool(true);
    setOpen(true);
    setAccessToken(localStorage.getItem("access_token"));
    setRefreshToken(localStorage.getItem("refresh_token"));
    if (tokenAccess && tokenRefresh) {
      setLoaderBool(false);
      history.push({
        pathname: `/main`,
        state: {
          access_token: tokenAccess,
          refresh_token: tokenRefresh,
        },
      });
    } else if (
      props.location.search &&
      props.location.search.split("?token=")[1]
    ) {
      setLoaderBool(false);
      let ru = "https://www.manageambassadors.com/main";
      window.location.href = `https://www.wix.com/installer/install?token=${
        props.location.search.split("?token=")[1]
      }&appId=d7ca7b96-91a2-439f-b420-6c3b21db769f&redirectUrl=${ru}`;
    } else if (
      props.location.search &&
      props.location.search.split("?code=")[1]
    ) {
      let urlA = props.location.search.split("?code=")[1];
      let oAuth = urlA.split("&")[0];
      let instanceId = props.location.search.split("&instanceId=")[1];
      localStorage.setItem("instanceId", instanceId);
      const fetchTokensAsync = async () => {
        handleToggle();
        const tkn = await getToken({ oAuth, instanceId });
        if (tkn) {
          localStorage.setItem("access_token", tkn.access_token);
          localStorage.setItem("refresh_token", tkn.refresh_token);
          setAccessToken(tkn.access_token);
          setRefreshToken(tkn.refresh_token);

          // close window
          handleClose();
          window.close();
          // history.push({
          //   pathname:  `/main`,
          //   state: {
          //     access_token : tokenAccess,
          //     refresh_token : tokenRefresh
          //   }
          // })

          //---------------------
        } else {
          handleClose();
          setLoaderBool(false);
          setErrText(true);
        }
      };
      // const tkn = getToken({oAuth, appId})
      // if(tkn){
      //   localStorage.setItem('access_token', tkn.access_token)
      //   localStorage.setItem('refresh_token', tkn.refresh_token)
      //   setAccessToken(tkn.access_token);
      //   setRefreshToken(tkn.refresh_token);
      //   history.push({
      //     pathname:  `/main`,
      //     state: {
      //       access_token : tokenAccess,
      //       refresh_token : tokenRefresh
      //     }
      //   })
      // }
      // else{
      //   setLoaderBool(false);
      //   setErrText(true);
      // }
    } else {
      setLoaderBool(false);
      handleClose();
      setErrText(true);
    }
    handleClose();
  }, []);

  return (
    <div
      style={{
        // backgroundImage: `url(${b})` ,
        height: "100%",
        position: "absolute",
        left: "0",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <Backdrop
        style={{ background: "black" }}
        className={classes.backdrop}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <Container component="main" maxWidth="xs" >
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar src={process.env.PUBLIC_URL + 'logo.png'} className={classes.avatar}>
        </Avatar>
        <Typography component="h1" variant="h5">
          Welcome to Ambassador Manager!
        </Typography>
        {loaderBool ?
        <Typography component="h2" variant="h6">
          Please wait while we log you in
        </Typography> 
        : null}
        {errText? 
        <Typography component="h2" variant="h6">
          Error occured! Please close the application and reopen again.
        </Typography> 
        : null}
        {loaderBool ? <CircularProgress /> : null}
        
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container> */}
    </div>
  );
}
