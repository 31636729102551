import React from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
} from '@material-ui/core';

const AppHeader = () => (
  <AppBar position="static" style={{backgroundColor: 'black'}}>
    <Toolbar style={{display: 'flex' , justifyContent: 'center'}}>
      <Typography variant="h6" color="inherit">
        Ambassador Manager
      </Typography>
    </Toolbar>
  </AppBar>
);

export default AppHeader;