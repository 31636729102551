import axios from "axios";

// let config = {
//     headers: {
//     "Access-Control-Allow-Origin": "https://fuzzy-earwig-12.loca.lt",
//     "x-Trigger": "CORS",
//     },
// }
let config, at, rt, instanceId;

let backendUrl = "https://manageambassadors.online";

export const getToken = async (body) => {
  debugger;
  const tokens = await axios.post(`${backendUrl}/initial/initialToken`, body);
  if (tokens) {
    debugger;
    console.log(tokens);
    return await tokens.data;
  } else {
    return null;
  }
};

export const getAllLocalContacts = async () => {
  debugger;
  instanceId = localStorage.getItem("instanceId");
  const localContacts = await axios.get(
    `${backendUrl}/initial/getAllLocalContacts?instanceId=${instanceId}`
  );
  if (localContacts) {
    debugger;
    console.log(localContacts);
    return await localContacts.data;
  } else {
    return null;
  }
};

export const getSettings = async () => {
  debugger;
  instanceId = localStorage.getItem("instanceId");
  const settings = await axios.get(
    `${backendUrl}/initial/getSettings?instanceId=${instanceId}`
  );
  if (settings) {
    debugger;
    console.log(settings);
    return await settings.data;
  } else {
    return null;
  }
};

export const updateSettings = async (bdy) => {
  debugger;
  instanceId = localStorage.getItem("instanceId");
  let body = {};
  body.instanceId = instanceId;
  body.settings = bdy;
  const settings = await axios.post(
    `${backendUrl}/initial/updateSettings`,
    body
  );
  if (settings) {
    debugger;
    console.log(settings);
    return await settings.data;
  } else {
    return null;
  }
};

export const getEmails = async () => {
  debugger;
  instanceId = localStorage.getItem("instanceId");
  const emails = await axios.get(
    `${backendUrl}/initial/getEmails?instanceId=${instanceId}`
  );
  if (emails) {
    debugger;
    console.log(emails);
    return await emails.data;
  } else {
    return null;
  }
};

export const updateEmails = async (bdy) => {
  debugger;
  instanceId = localStorage.getItem("instanceId");
  let body = {};
  body.instanceId = instanceId;
  body.emails = bdy;
  const emails = await axios.post(`${backendUrl}/initial/updateEmails`, body);
  if (emails) {
    debugger;
    console.log(emails);
    return await emails.data;
  } else {
    return null;
  }
};

export const syncContacts = async (data) => {
  debugger;
  let body = {};
  body.formContacts = data;
  body.instanceId = localStorage.getItem("instanceId");
  const localContacts = await axios.post(
    `${backendUrl}/initial/syncContacts`,
    body
  );
  if (localContacts) {
    debugger;
    console.log(localContacts);
    return await localContacts.data;
  } else {
    return null;
  }
};

export const getCouponById = async (body) => {
  debugger;
  at = localStorage.getItem("access_token");
  rt = localStorage.getItem("refresh_token");
  config = {
    headers: {
      access_token: at,
      refresh_token: rt,
    },
  };
  const coupons = await axios.get(
    `${backendUrl}/coupons/getCouponById?id=${body.id}`,
    config
  );
  if (coupons) {
    debugger;
    console.log(coupons);
    return await coupons.data;
  } else {
    return null;
  }
};

export const getAllContacts = async () => {
  debugger;
  at = localStorage.getItem("access_token");
  rt = localStorage.getItem("refresh_token");
  config = {
    headers: {
      access_token: at,
      refresh_token: rt,
    },
  };
  const contacts = await axios.post(
    `${backendUrl}/contacts/getAllContacts`,
    {},
    config
  );
  if (contacts) {
    debugger;
    console.log(contacts);
    return await contacts.data;
  } else {
    return null;
  }
};

export const createPromoCodeApi = async (body) => {
  debugger;
  at = localStorage.getItem("access_token");
  rt = localStorage.getItem("refresh_token");
  instanceId = localStorage.getItem("instanceId");
  body.instanceId = instanceId;
  body.access_token = at;
  body.refresh_token = rt;
  // config = {
  //     headers: {
  //         "access_token": at,
  //         "refresh_token": rt,
  //     },
  // }
  const promoCode = await axios.post(
    `${backendUrl}/coupons/createPromoCode`,
    body
  );
  if (promoCode) {
    debugger;
    console.log(promoCode);
    return await promoCode.data;
  } else {
    return null;
  }
};

export const deleteUserApi = async (body) => {
  debugger;
  at = localStorage.getItem("access_token");
  rt = localStorage.getItem("refresh_token");
  instanceId = localStorage.getItem("instanceId");
  body.instanceId = instanceId;
  body.access_token = at;
  body.refresh_token = rt;
  // config = {
  //     headers: {
  //         "access_token": at,
  //         "refresh_token": rt,
  //     },
  // }
  const delResp = await axios.post(`${backendUrl}/coupons/deleteUser`, body);
  if (delResp) {
    debugger;
    console.log(delResp);
    return await delResp.data;
  } else {
    return null;
  }
};

export const getTokens = async () => {
  debugger;
  let body = {};
  at = localStorage.getItem("access_token");
  rt = localStorage.getItem("refresh_token");
  instanceId = localStorage.getItem("instanceId");
  body.instanceId = instanceId;
  body.access_token = at;
  body.refresh_token = rt;
  try {
    const getToken = await axios.post(`${backendUrl}/contacts/getTokens`, body);
    if (getToken.status && getToken.status === "401") {
      return null;
    } else if (getToken) {
      debugger;
      console.log(getToken);
      return await getToken.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
};

export const fetchBusinessName = async () => {
  debugger;
  at = localStorage.getItem("access_token");
  rt = localStorage.getItem("refresh_token");
  instanceId = localStorage.getItem("instanceId");
  let body = {};
  body.instanceId = instanceId;
  body.access_token = at;
  body.refresh_token = rt;
  // config = {
  //     headers: {
  //         "access_token": at,
  //         "refresh_token": rt,
  //     },
  // }
  const businessName = await axios.post(
    `${backendUrl}/coupons/fetchBusinessName`,
    body
  );
  if (businessName) {
    debugger;
    console.log(businessName);
    return await businessName.data;
  } else {
    return null;
  }
};

export const fetchInstance = async () => {
  debugger;
  at = localStorage.getItem("access_token");
  rt = localStorage.getItem("refresh_token");
  instanceId = localStorage.getItem("instanceId");
  let body = {};
  body.instanceId = instanceId;
  body.access_token = at;
  body.refresh_token = rt;
  // config = {
  //     headers: {
  //         "access_token": at,
  //         "refresh_token": rt,
  //     },
  // }
  const Instance = await axios.post(
    `${backendUrl}/coupons/fetchInstance`,
    body
  );
  if (Instance) {
    debugger;
    console.log(Instance);
    return await Instance.data;
  } else {
    return null;
  }
};

export const fetchUpgradeURL = async () => {
  debugger;
  at = localStorage.getItem("access_token");
  rt = localStorage.getItem("refresh_token");
  instanceId = localStorage.getItem("instanceId");
  let body = {};
  body.instanceId = instanceId;
  body.access_token = at;
  body.refresh_token = rt;
  // config = {
  //     headers: {
  //         "access_token": at,
  //         "refresh_token": rt,
  //     },
  // }
  const UpgradeData = await axios.post(
    `${backendUrl}/coupons/fetchUpgradeURL`,
    body
  );
  if (UpgradeData) {
    debugger;
    console.log(UpgradeData);
    return await UpgradeData.data;
  } else {
    return null;
  }
};

export const unsubscribeUser = async (body) => {
  debugger;
  const unsubscribeData = await axios.post(
    `${backendUrl}/initial/unsubscribeUser`,
    body
  );
  if (unsubscribeData) {
    debugger;
    console.log(unsubscribeData);
    return await unsubscribeData.data;
  } else {
    return null;
  }
};
