import React, { Fragment } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { CssBaseline, withStyles } from "@material-ui/core";

import AppHeader from "./components/AppHeader";
import Home from "./components/Home";
import MainLanding from "./components/MainLanding";
import Unsubscribe from "./components/Unsubscribe";

const styles = (theme) => ({
  main: {
    padding: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
    },
  },
});

const App = ({ classes }) => (
  <Router>
    <Fragment>
      <CssBaseline />
      <AppHeader />

      {/* <Home /> */}
    </Fragment>

    <Switch>
      <main className={classes.main}>
        <Route exact path="/" component={Home} />
        <Route path="/main" component={MainLanding} />
        {/* <Route path='/signup' component={Unsubscribe} /> */}
        <Route path="/unsubscribe" component={Unsubscribe} />
      </main>
    </Switch>
  </Router>
);

export default withStyles(styles)(App);
