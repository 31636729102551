import React, { useEffect } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SaveIcon from "@material-ui/icons/Save";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import Switch from "@material-ui/core/Switch";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Papa from "papaparse";
import { Link } from "react-router-dom";
import {
  getToken,
  getSettings,
  updateSettings,
  getEmails,
  updateEmails,
  getAllLocalContacts,
  syncContacts,
  createPromoCodeApi,
  deleteUserApi,
  fetchBusinessName,
  fetchInstance,
  fetchUpgradeURL,
  getTokens,
  unsubscribeUser,
} from "../services/services";
import jwt_decode from "jwt-decode";
// import XLSX from 'xlsx';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  table: {
    minWidth: 700,
  },
  container: {
    maxHeight: 600,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  button: {
    margin: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function CenteredTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [accessToken, setAccessToken] = React.useState("");
  const [refreshToken, setRefreshToken] = React.useState("");
  const [expanded, setExpanded] = React.useState(false);
  const [expandedEmail, setExpandedEmail] = React.useState(false);
  const [expanded1, setExpanded1] = React.useState(false);
  const [contacts, setContacts] = React.useState([]);
  const [instanceData, setInstanceData] = React.useState("");
  const [freemium, setFreemium] = React.useState("30");
  const [upgradeURL, setUpgradeURL] = React.useState("");
  const [valDiscount, setValDiscount] = React.useState("percentage");
  const [csvFile, setCsvFile] = React.useState("");
  const [csvFileType, setCsvFileType] = React.useState("");
  const [tempAmbassador, setTempAmbassador] = React.useState(
    "<p>Hi Ambassador!</p><p><br></p><p>You did it , this is the first time the code has been used. 9 more times and you get a $50 voucher!</p><p><br></p><p>Keep it up,</p><p>FlyCheerGear</p>"
  );
  const [temp1, setTemp1] = React.useState("");
  const [temp2, setTemp2] = React.useState("");
  const [temp3, setTemp3] = React.useState("");
  const [temp4, setTemp4] = React.useState("");
  const [temp5, setTemp5] = React.useState("");
  const [temp6, setTemp6] = React.useState("");
  const [temp7, setTemp7] = React.useState("");
  const [temp8, setTemp8] = React.useState("");
  const [temp9, setTemp9] = React.useState("");
  const [tempFinal, setTempFinal] = React.useState("");
  const [tempOthers, setTempOthers] = React.useState("");
  const [typeOfPrizeCoupon, setTypeOfPrizeCoupon] = React.useState("");
  const [amountOfPrizeCoupon, setAmountOfPrizeCoupon] = React.useState("");
  const [couponUsagePrizeCount, setCouponUsagePrizeCount] = React.useState("");
  const [amountOfPromoCode, setAmountOfPromoCode] = React.useState("");
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (
      localStorage.getItem("access_token") &&
      localStorage.getItem("refresh_token")
    ) {
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("instanceId");
    }
    if (props.location.search && props.location.search.split("?code=")[1]) {
      let urlA = props.location.search.split("?code=")[1];
      let oAuth = urlA.split("&")[0];
      let instanceId = props.location.search.split("&instanceId=")[1];
      localStorage.setItem("instanceId", instanceId);
      const fetchTokensAsync = async () => {
        handleToggle();
        let closeWindowBool = true;
        const tkn = await getToken({ oAuth, instanceId, closeWindowBool });
        if (tkn) {
          localStorage.setItem("access_token", tkn.access_token);
          localStorage.setItem("refresh_token", tkn.refresh_token);
          setAccessToken(tkn.access_token);
          setRefreshToken(tkn.refresh_token);

          let currURL = window.location.href;
          let url = currURL.split(window.location.host)[1].split("?")[0];
          window.history.pushState({}, document.title, url);

          const settingsFromDB = await getSettings();
          if (settingsFromDB) {
            setTypeOfPrizeCoupon(settingsFromDB.typeOfPrizeCoupon);
            setAmountOfPrizeCoupon(settingsFromDB.amountOfPrizeCoupon);
            setCouponUsagePrizeCount(settingsFromDB.couponUsagePrizeCount);
            setAmountOfPromoCode(settingsFromDB.amountOfPromoCode);
            setFreemium(settingsFromDB.contactGuard);
          }

          const businessName = await fetchBusinessName();
          let access_token = tkn.access_token;
          let refresh_token = tkn.refresh_token;
          const newSettings1 = await updateSettings({
            access_token,
            refresh_token,
          });
          if (newSettings1) {
            setTypeOfPrizeCoupon(newSettings1.typeOfPrizeCoupon);
            setAmountOfPrizeCoupon(newSettings1.amountOfPrizeCoupon);
            setCouponUsagePrizeCount(newSettings1.couponUsagePrizeCount);
            setAmountOfPromoCode(newSettings1.amountOfPromoCode);
            setFreemium(newSettings1.contactGuard);
            console.log(newSettings1);
          }
          const InstanceName = await fetchInstance();
          if (InstanceName) {
            setInstanceData(InstanceName);
          }
          const contactsAllLocal = await getAllLocalContacts();
          if (contactsAllLocal) {
            setContacts(contactsAllLocal);
          }

          const emailsFromDB = await getEmails();
          if (emailsFromDB) {
            setTempAmbassador(emailsFromDB.tempAmbassador);
            setTemp1(emailsFromDB.temp1);
            setTemp2(emailsFromDB.temp2);
            setTemp3(emailsFromDB.temp3);
            setTemp4(emailsFromDB.temp4);
            setTemp5(emailsFromDB.temp5);
            setTemp6(emailsFromDB.temp6);
            setTemp7(emailsFromDB.temp7);
            setTemp8(emailsFromDB.temp8);
            setTemp9(emailsFromDB.temp9);
            setTempFinal(emailsFromDB.tempFinal);
            setTempOthers(emailsFromDB.tempOthers);
          }

          // now close window
          handleClose();
          window.location.href = `https://www.wix.com/installer/token-received?access_token=${tkn.access_token}`;
          // window.close();
        }
      };
      fetchTokensAsync();
    } else if (
      props.location.search &&
      props.location.search.split("?instance=")[1]
    ) {
      let urlI = props.location.search.split("?instance=")[1];
      let instance = urlI.split("&")[0];
      console.log("INSTANCE========>", instance);
      let body = jwt_decode(instance);
      console.log("DECODED========>", body);
      localStorage.setItem("instanceId", body.instanceId);
      const instanceConditionFunc = async () => {
        const settingsFromDB = await getSettings();
        if (settingsFromDB) {
          setTypeOfPrizeCoupon(settingsFromDB.typeOfPrizeCoupon);
          setAmountOfPrizeCoupon(settingsFromDB.amountOfPrizeCoupon);
          setCouponUsagePrizeCount(settingsFromDB.couponUsagePrizeCount);
          setAmountOfPromoCode(settingsFromDB.amountOfPromoCode);
          setFreemium(settingsFromDB.contactGuard);
        }
        let access_token = settingsFromDB.access_token;
        let refresh_token = settingsFromDB.refresh_token;
        localStorage.setItem("access_token", access_token);
        localStorage.setItem("refresh_token", refresh_token);

        const businessName = await fetchBusinessName();
        const InstanceName = await fetchInstance();
        if (InstanceName) {
          setInstanceData(InstanceName);
        }
        const contactsAllLocal = await getAllLocalContacts();
        if (contactsAllLocal) {
          setContacts(contactsAllLocal);
        }

        const emailsFromDB = await getEmails();
        if (emailsFromDB) {
          setTempAmbassador(emailsFromDB.tempAmbassador);
          setTemp1(emailsFromDB.temp1);
          setTemp2(emailsFromDB.temp2);
          setTemp3(emailsFromDB.temp3);
          setTemp4(emailsFromDB.temp4);
          setTemp5(emailsFromDB.temp5);
          setTemp6(emailsFromDB.temp6);
          setTemp7(emailsFromDB.temp7);
          setTemp8(emailsFromDB.temp8);
          setTemp9(emailsFromDB.temp9);
          setTempFinal(emailsFromDB.tempFinal);
          setTempOthers(emailsFromDB.tempOthers);
        }
      };
      instanceConditionFunc();
    } else if (
      props.location &&
      props.location.state &&
      props.location.state.access_token &&
      props.location.state.refresh_token
    ) {
      setAccessToken(props.location.state.access_token);
      setRefreshToken(props.location.state.refresh_token);

      const routeConditionFunc = async () => {
        const settingsFromDB = await getSettings();
        if (settingsFromDB) {
          setTypeOfPrizeCoupon(settingsFromDB.typeOfPrizeCoupon);
          setAmountOfPrizeCoupon(settingsFromDB.amountOfPrizeCoupon);
          setCouponUsagePrizeCount(settingsFromDB.couponUsagePrizeCount);
          setAmountOfPromoCode(settingsFromDB.amountOfPromoCode);
          setFreemium(settingsFromDB.contactGuard);
        }

        const businessName = await fetchBusinessName();
        let access_token = props.location.state.access_token;
        let refresh_token = props.location.state.refresh_token;
        const newSettings1 = await updateSettings({
          access_token,
          refresh_token,
        });
        if (newSettings1) {
          setTypeOfPrizeCoupon(newSettings1.typeOfPrizeCoupon);
          setAmountOfPrizeCoupon(newSettings1.amountOfPrizeCoupon);
          setCouponUsagePrizeCount(newSettings1.couponUsagePrizeCount);
          setAmountOfPromoCode(newSettings1.amountOfPromoCode);
          setFreemium(newSettings1.contactGuard);
          console.log(newSettings1);
        }
        const InstanceName = await fetchInstance();
        if (InstanceName) {
          setInstanceData(InstanceName);
        }
        const contactsAllLocal = await getAllLocalContacts();
        if (contactsAllLocal) {
          setContacts(contactsAllLocal);
        }
        const emailsFromDB = await getEmails();
        if (emailsFromDB) {
          setTempAmbassador(emailsFromDB.tempAmbassador);
          setTemp1(emailsFromDB.temp1);
          setTemp2(emailsFromDB.temp2);
          setTemp3(emailsFromDB.temp3);
          setTemp4(emailsFromDB.temp4);
          setTemp5(emailsFromDB.temp5);
          setTemp6(emailsFromDB.temp6);
          setTemp7(emailsFromDB.temp7);
          setTemp8(emailsFromDB.temp8);
          setTemp9(emailsFromDB.temp9);
          setTempFinal(emailsFromDB.tempFinal);
          setTempOthers(emailsFromDB.tempOthers);
        }
      };
      routeConditionFunc();
    } else if (props.location.search.split("?token=")[1]) {
      let ru = "https://www.manageambassadors.com/main";
      window.location.href = `https://www.wix.com/installer/install?token=${
        props.location.search.split("?token=")[1]
      }&appId=d7ca7b96-91a2-439f-b420-6c3b21db769f&redirectUrl=${ru}`;
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleEmailAccordion = (panel) => (event, isExpanded) => {
    setExpandedEmail(isExpanded ? panel : false);
  };

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChangeAccordion1 = (panel) => (event, isExpanded) => {
    setExpanded1(isExpanded ? panel : false);
  };

  const handleRadioChange = (event) => {
    setTypeOfPrizeCoupon(event.target.value);
  };

  const aopcChange = (event) => {
    setAmountOfPrizeCoupon(event.target.value);
  };

  const cupcChange = (event) => {
    setCouponUsagePrizeCount(event.target.value);
  };

  const aopromocodeChange = (event) => {
    setAmountOfPromoCode(event.target.value);
  };

  const handleChangeCsv = (event) => {
    setCsvFile(event.target.files[0]);
    // setCsvFileType(event.target.files[0].type);
  };

  const clearCSV = (event) => {
    setCsvFile("");
    // setCsvFileType('');
  };

  const uploadCSV = (event) => {
    if (!csvFile) {
      window.alert("Please Select CSV file");
      return;
    }
    let csv = csvFile;
    // if(csvFileType == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"){
    //   var f = csvFile;
    //   var reader = new FileReader();
    //   reader.onload = function (e) {
    //       var data = e.target.result;
    //       let readedData = XLSX.read(data, {type: 'binary'});
    //       const wsname = readedData.SheetNames[0];
    //       const ws = readedData.Sheets[wsname];

    //       /* Convert array to json*/
    //       debugger;
    //       const dataParse = XLSX.utils.sheet_to_json(ws, {header:1});
    //       console.log("XLSX--------------------------->")
    //       if(dataParse){
    //         dataParse.map((dp)=>{
    //           console.log("---------------------------------------------->")
    //           if(dp.length>0){
    //             dp.map((d)=>{
    //               console.log(d);
    //             })
    //           }
    //         })
    //       }
    //   };
    //   reader.readAsBinaryString(f)

    // }
    // else{
    Papa.parse(csv, {
      complete: processCSV,
      header: true,
    });
    // }
  };

  const upgradePlan = async () => {
    // const upgradeURLData = await fetchUpgradeURL();
    // if (upgradeURLData) {
    //   setUpgradeURL(upgradeURLData);
    //   window.location.href = upgradeURLData.checkoutUrl;
    // }
    let a = document.createElement("a");
    a.target = "_blank";
    let instanceId = localStorage.getItem("instanceId");
    if (!instanceId) {
      instanceId = instanceData.instanceId ? instanceData.instanceId : "";
    }
    a.href = `https://www.wix.com/apps/upgrade/d7ca7b96-91a2-439f-b420-6c3b21db769f?appInstanceId=${instanceId}`;
    a.click();
  };

  const updateSettingsBtn = async () => {
    if (amountOfPrizeCoupon < 0.01) {
      window.alert("Amount of Prize Coupon should be greater than 0");
      return;
    }
    if (couponUsagePrizeCount < 1) {
      window.alert("Usage count should be greater than 1");
      return;
    }
    if (amountOfPromoCode < 0.01) {
      window.alert("Amount of Promo Code should be greater than 0");
      return;
    }
    const newSettings = await updateSettings({
      typeOfPrizeCoupon,
      amountOfPrizeCoupon,
      couponUsagePrizeCount,
      amountOfPromoCode,
    });
    if (newSettings) {
      setTypeOfPrizeCoupon(newSettings.typeOfPrizeCoupon);
      setAmountOfPrizeCoupon(newSettings.amountOfPrizeCoupon);
      setCouponUsagePrizeCount(newSettings.couponUsagePrizeCount);
      setAmountOfPromoCode(newSettings.amountOfPromoCode);
      console.log(newSettings);
      window.alert("Settings Updated Successfully!");
    }
  };

  const updateTemplatesBtn = async () => {
    if (tempAmbassador && tempAmbassador.length == 0) {
      window.alert("Template for Initial Email cannot be empty");
      return;
    }
    if (temp1 && temp1.length == 0) {
      window.alert("Template for Code Usage 1 cannot be empty");
      return;
    }
    if (temp2 && temp2.length == 0) {
      window.alert("Template for Code Usage 2 cannot be empty");
      return;
    }
    if (temp3 && temp3.length == 0) {
      window.alert("Template for Code Usage 3 cannot be empty");
      return;
    }
    if (temp4 && temp4.length == 0) {
      window.alert("Template for Code Usage 4 cannot be empty");
      return;
    }
    if (temp5 && temp5.length == 0) {
      window.alert("Template for Code Usage 5 cannot be empty");
      return;
    }
    if (temp6 && temp6.length == 0) {
      window.alert("Template for Code Usage 6 cannot be empty");
      return;
    }
    if (temp7 && temp7.length == 0) {
      window.alert("Template for Code Usage 7 cannot be empty");
      return;
    }
    if (temp8 && temp8.length == 0) {
      window.alert("Template for Code Usage 8 cannot be empty");
      return;
    }
    if (temp9 && temp9.length == 0) {
      window.alert("Template for Code Usage 9 cannot be empty");
      return;
    }
    if (tempFinal && tempFinal.length == 0) {
      window.alert("Template for Final Code Usage cannot be empty");
      return;
    }
    if (tempOthers && tempOthers.length == 0) {
      window.alert("Template for Code Usage above 10 cannot be empty");
      return;
    }

    console.log(tempAmbassador);
    console.log(temp1);
    console.log(temp2);
    console.log(temp3);
    console.log(temp4);
    console.log(temp5);
    console.log(temp6);
    console.log(temp7);
    console.log(temp8);
    console.log(temp9);
    console.log(tempFinal);
    console.log(tempOthers);
    const newEmails = await updateEmails({
      tempAmbassador,
      temp1,
      temp2,
      temp3,
      temp4,
      temp5,
      temp6,
      temp7,
      temp8,
      temp9,
      tempFinal,
      tempOthers,
    });
    if (newEmails) {
      setTempAmbassador(newEmails.tempAmbassador);
      setTemp1(newEmails.temp1);
      setTemp2(newEmails.temp2);
      setTemp3(newEmails.temp3);
      setTemp4(newEmails.temp4);
      setTemp5(newEmails.temp5);
      setTemp6(newEmails.temp6);
      setTemp7(newEmails.temp7);
      setTemp8(newEmails.temp8);
      setTemp9(newEmails.temp9);
      setTempFinal(newEmails.tempFinal);
      setTempOthers(newEmails.tempOthers);
      window.alert("Email Templates updated Successfully!");
    }
  };

  const processCSV = async (result) => {
    debugger;
    // handleToggle();
    const shouldAppHeader = ["Email", "ID", "First Name", "Last Name"];

    var data = result.data;
    console.log("CSVVVV ---------------------->", data);
    // data.foreach((dt)=>{
    //   if(dt.Email===""){
    //     dt.pop();
    //   }
    // })
    if (data[data.length - 1].Email == "") {
      data.pop(); //if last row is empty remove it
    }
    let currentAppHeader = [];
    currentAppHeader = Object.keys(data[0]);
    let boolForLoop = false;
    for (var i = 0; i < shouldAppHeader.length; i++) {
      let columnsMismatch = false;
      for (var j = 0; j < currentAppHeader.length; j++) {
        if (shouldAppHeader[i] == currentAppHeader[j]) {
          columnsMismatch = true;
        }
      }
      if (columnsMismatch == false) {
        //window.alert('Invalid CSV format');
        boolForLoop = true;
        break;
      }
    }

    if (!boolForLoop) {
      const syncedContacts = await syncContacts(data);
      if (syncedContacts) {
        const contactsAllLocal = await getAllLocalContacts();
        if (contactsAllLocal) {
          setContacts(contactsAllLocal);
          window.alert("New contacts uploaded successfully!");
        }
        // handleClose();
      }
    } else {
      window.alert("Invalid CSV format");
      // handleClose();
    }
  };

  const createPromoCode = async (e, id, row) => {
    let firstName = row.firstName;
    let lastName = row.lastName;
    const promocode = await createPromoCodeApi({ id, firstName, lastName });
    if (promocode) {
      const contactsAllLocal = await getAllLocalContacts();
      if (contactsAllLocal) {
        setContacts(contactsAllLocal);
      }
      window.alert("Coupon Created Successfully");
    }
  };

  const deleteUser = async (e, id, row) => {
    let firstName = row.firstName;
    let lastName = row.lastName;
    const deletedResp = await deleteUserApi({ id, firstName, lastName });
    if (deletedResp) {
      const contactsAllLocal = await getAllLocalContacts();
      if (contactsAllLocal) {
        setContacts(contactsAllLocal);
      }
      window.alert("User deleted Successfully");
    }
  };

  const handleChangeSubscription = async (e, id, row) => {
    const unsubscribeResp = await unsubscribeUser({
      email: row.email,
      unsubscribed: e.target.checked ? false : true,
      sentByApp: true,
    });
    if (unsubscribeResp) {
      const contactsAllLocal = await getAllLocalContacts();
      if (contactsAllLocal) {
        setContacts(contactsAllLocal);
      }
      window.alert("User email subscription changed Successfully!");
    } else {
      const contactsAllLocal = await getAllLocalContacts();
      if (contactsAllLocal) {
        setContacts(contactsAllLocal);
      }
      window.alert("User email subscription was not changed!");
    }
  };

  let contactGuard = 0;
  return (
    <div
      style={
        {
          // backgroundImage: `url(${b})` ,
          // height:'100%',position: 'absolute', left: '0',width: '100%',overflow: 'hidden'
        }
      }
    >
      <Backdrop
        style={{ background: "black" }}
        className={classes.backdrop}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div style={{ paddingBottom: 20 }}>
        {instanceData && !instanceData.instance.isFree ? (
          <div></div>
        ) : (
          <div style={{ paddingBottom: 20 }}>
            <center>
              <Typography className={classes.heading}>
                Upgrade to Premium - manage more ambassadors with a premium
                version! (Free version only supports up to 30 Ambassadors)
              </Typography>
              <Button
                style={{}}
                variant="contained"
                size="small"
                color="primary"
                className={classes.margin}
                onClick={upgradePlan}
              >
                Upgrade
              </Button>
            </center>
          </div>
        )}
        <div style={{ paddingBottom: 20 }}>
          <center>
            <Typography className={classes.heading}>
              For any queries - Email at contact@ambassadormanager.com
            </Typography>
          </center>
        </div>
        <div style={{ paddingBottom: 20 }}>
          <center>
            <Typography className={classes.heading}>
              Step by step instructions on our website www.ambassadormanager.com
            </Typography>
          </center>
        </div>
        <div style={{ paddingBottom: 20 }}>
          <center>
            <a
              href="https://youtu.be/d2AHgRXjdZY"
              target="_blank"
              rel="noreferrer"
            >
              <Typography className={classes.heading}>
                Click here to view tutorial
              </Typography>
            </a>
          </center>
        </div>

        <Paper className={classes.root}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Contacts" />
            <Tab label="Settings" />
            <Tab label="Emails" />
          </Tabs>
        </Paper>
      </div>
      {value == 0 ? (
        <div>
          <div style={{ paddingBottom: 20 }}>
            <Accordion
              expanded={expanded1 === "panel01"}
              onChange={handleChangeAccordion1("panel01")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel0bh-content"
                id="panel0bh-header"
              >
                <Typography className={classes.heading}>Upload CSV</Typography>
              </AccordionSummary>
              <AccordionDetails
                style={{ display: "flex", justifyContent: "center" }}
              >
                <table>
                  <tr>
                    <center>
                      <Typography
                        style={{ paddingBottom: 15 }}
                        className={classes.heading}
                      >
                        Instruction : Only upload CSV file which has these
                        mandatory columns and exact same column names as
                        mentioned below!
                      </Typography>
                    </center>
                    <center>
                      <Typography
                        style={{ paddingBottom: 15 }}
                        className={classes.heading}
                      >
                        Column Names = 'Email','ID', 'First Name', 'Last Name'
                      </Typography>
                    </center>
                    <center>
                      <Typography
                        style={{ paddingBottom: 15 }}
                        className={classes.heading}
                      >
                        Note : Any forms that have the name 'Ambassador' in it
                        and have the above mentioned column on your site are
                        automatically attached with Ambassador Manager
                      </Typography>
                    </center>
                    <center>
                      <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        className={classes.margin}
                      >
                        <a
                          href="https://uploadsfcg.s3.us-east-2.amazonaws.com/SampleForAmbassadorManager.csv"
                          target="_blank"
                          rel="noreferrer"
                          download
                        >
                          Download Sample
                        </a>
                      </Button>
                    </center>
                  </tr>
                  <tr>
                    {csvFile ? (
                      <center>
                        <Typography
                          style={{ paddingBottom: 15 }}
                          className={classes.heading}
                        >
                          <b>Upload selected CSV</b>
                        </Typography>
                      </center>
                    ) : (
                      <center>
                        <Typography
                          style={{ paddingBottom: 15 }}
                          className={classes.heading}
                        >
                          <b>Choose a CSV file</b>
                        </Typography>
                      </center>
                    )}
                  </tr>
                  <tr>
                    <center>
                      <input
                        style={{ paddingBottom: 15 }}
                        className="csv-input"
                        type="file"
                        accept={".csv"}
                        // ref={input => {
                        //   filesInput = input;
                        // }}
                        name="file"
                        placeholder={null}
                        onChange={handleChangeCsv}
                      />
                    </center>
                  </tr>
                  <tr style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ paddingRight: 10 }}>
                      <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        onClick={uploadCSV}
                        className={classes.margin}
                      >
                        Upload CSV
                      </Button>
                    </div>
                    <div>
                      <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        onClick={clearCSV}
                        className={classes.margin}
                      >
                        Clear CSV
                      </Button>
                    </div>
                  </tr>
                </table>
              </AccordionDetails>
            </Accordion>
          </div>
          <TableContainer className={classes.container} component={Paper}>
            <Table
              stickyHeader
              aria-label="sticky table"
              className={classes.table}
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>No</StyledTableCell>
                  <StyledTableCell>Id</StyledTableCell>
                  <StyledTableCell align="right">First Name</StyledTableCell>
                  <StyledTableCell align="right">Last Name</StyledTableCell>
                  <StyledTableCell align="right">Promo Code</StyledTableCell>
                  <StyledTableCell align="right">
                    Current Usage Count
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    Total Usage Count
                  </StyledTableCell>
                  <StyledTableCell align="right">Email</StyledTableCell>
                  <StyledTableCell align="right">Username</StyledTableCell>
                  <StyledTableCell align="right">Birthday</StyledTableCell>
                  <StyledTableCell align="right">
                    Send Subscription Emails
                  </StyledTableCell>
                  <StyledTableCell align="right">Actions</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contacts ? (
                  contacts.map((row, index) => {
                    if (instanceData.instance.isFree) {
                      contactGuard++;
                      if (contactGuard <= freemium) {
                        return (
                          <StyledTableRow key={row.wixId}>
                            <StyledTableCell component="th" scope="row">
                              {index + 1}
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              {row.wixId}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {row.firstName ? row.firstName : ""}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {row.lastName ? row.lastName : ""}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {row.wixCoupon && row.wixCoupon.length > 0
                                ? row.wixCoupon[0].code
                                : ""}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {row.wixCoupon && row.wixCoupon.length > 0
                                ? row.wixCoupon[0].currentUsageCount
                                : ""}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {row.wixCoupon && row.wixCoupon.length > 0
                                ? row.wixCoupon[0].totalUsageCount
                                : ""}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {row.email ? row.email : ""}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {row.username ? row.username : ""}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {row.birthday ? row.birthday : ""}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              <Switch
                                checked={row.unsubscribed ? false : true}
                                onChange={(e) => {
                                  handleChangeSubscription(e, row.id, row);
                                }}
                                aria-label="controlled"
                              />
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {row.wixCoupon && row.wixCoupon.length > 0 ? (
                                <Button
                                  style={{ backgroundColor: "indianred" }}
                                  variant="contained"
                                  size="small"
                                  color="primary"
                                  className={classes.margin}
                                  onClick={(e) => {
                                    deleteUser(e, row.id, row);
                                  }}
                                >
                                  Delete User
                                </Button>
                              ) : (
                                <Button
                                  variant="outlined"
                                  size="small"
                                  color="primary"
                                  onClick={(e) => {
                                    createPromoCode(e, row.id, row);
                                  }}
                                  className={classes.margin}
                                >
                                  Create Code
                                </Button>
                              )}
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      } else {
                      }
                    } else {
                      return (
                        <StyledTableRow key={row.wixId}>
                          <StyledTableCell component="th" scope="row">
                            {index + 1}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {row.wixId}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {row.firstName ? row.firstName : ""}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {row.lastName ? row.lastName : ""}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {row.wixCoupon && row.wixCoupon.length > 0
                              ? row.wixCoupon[0].code
                              : ""}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {row.wixCoupon && row.wixCoupon.length > 0
                              ? row.wixCoupon[0].currentUsageCount
                              : ""}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {row.wixCoupon && row.wixCoupon.length > 0
                              ? row.wixCoupon[0].totalUsageCount
                              : ""}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {row.email ? row.email : ""}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {row.username ? row.username : ""}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {row.birthday ? row.birthday : ""}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            <Switch
                              checked={row.unsubscribed ? false : true}
                              onChange={(e) => {
                                handleChangeSubscription(e, row.id, row);
                              }}
                              aria-label="controlled"
                            />
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {row.wixCoupon && row.wixCoupon.length > 0 ? (
                              <Button
                                style={{ backgroundColor: "indianred" }}
                                variant="contained"
                                size="small"
                                color="primary"
                                className={classes.margin}
                                onClick={(e) => {
                                  deleteUser(e, row.id, row);
                                }}
                              >
                                Delete User
                              </Button>
                            ) : (
                              <Button
                                variant="outlined"
                                size="small"
                                color="primary"
                                onClick={(e) => {
                                  createPromoCode(e, row.id, row);
                                }}
                                className={classes.margin}
                              >
                                Create Code
                              </Button>
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    }
                  })
                ) : (
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row">
                      <Typography component="h2" variant="h6">
                        No Contacts added yet
                      </Typography>
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : null}
      {value == 1 ? (
        <div>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChangeAccordion("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className={classes.heading}>
                Ambassador Reward
              </Typography>
              <Typography className={classes.secondaryHeading}>
                Tap to select reward ambassador receives
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{ display: "flex", justifyContent: "center" }}
            >
              <table>
                <tr>
                  <RadioGroup
                    aria-label="quiz"
                    name="quiz"
                    value={typeOfPrizeCoupon}
                    onChange={handleRadioChange}
                    style={{ paddingBottom: "20px" }}
                  >
                    <FormControlLabel
                      value="PercentOff"
                      control={<Radio />}
                      label="% discount"
                    />
                    <FormControlLabel
                      value="MoneyOff"
                      control={<Radio />}
                      label="$ discount"
                    />
                  </RadioGroup>
                </tr>
                <tr>
                  <TextField
                    id="prizeAmount"
                    label="Prize Amount"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={amountOfPrizeCoupon}
                    onChange={aopcChange}
                    variant="outlined"
                  />
                </tr>
              </table>
            </AccordionDetails>
          </Accordion>

          {/* <Accordion expanded={expanded === 'panel2'} onChange={handleChangeAccordion('panel2')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography className={classes.heading}>Email Timer</Typography>
              <Typography className={classes.secondaryHeading}>Write no of days it will take the email of acceptance to reach clients</Typography>
            </AccordionSummary>
            <AccordionDetails  style={{display: 'flex' , justifyContent: 'center'}} >
              
                <TextField
                  id="emailNumber"
                  label="No of days"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              
            </AccordionDetails>
          </Accordion> */}

          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChangeAccordion("panel3")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography className={classes.heading}>Usage Count</Typography>
              <Typography className={classes.secondaryHeading}>
                Write the number of times code is used to receive reward
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{ display: "flex", justifyContent: "center" }}
            >
              <TextField
                id="usageCount"
                label="Count"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                value={couponUsagePrizeCount}
                onChange={cupcChange}
                variant="outlined"
              />
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChangeAccordion("panel4")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography className={classes.heading}>
                Promo Code Discount
              </Typography>
              <Typography className={classes.secondaryHeading}>
                Write the % discount of promocode an ambassador initially gets
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{ display: "flex", justifyContent: "center" }}
            >
              <TextField
                id="promocodeValue"
                label="Promo Code Discount"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                value={amountOfPromoCode}
                onChange={aopromocodeChange}
                variant="outlined"
              />
              <Typography
                style={{ paddingTop: "1%", paddingLeft: "5px" }}
                className={classes.secondaryHeading}
              >
                %
              </Typography>
            </AccordionDetails>
          </Accordion>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              color="default"
              size="large"
              className={classes.button}
              startIcon={<SaveIcon />}
              onClick={updateSettingsBtn}
            >
              Save
            </Button>
          </div>
        </div>
      ) : null}
      {value == 2 ? (
        <div>
          <div style={{ paddingBottom: 20 }}>
            <center>
              <Typography
                style={{ paddingBottom: 15 }}
                className={classes.heading}
              >
                Instructions : In order to edit the templates, Please replace
                the text with the below mentioned (exact) replacements where
                needed
              </Typography>
            </center>
            <Typography
              style={{ paddingBottom: 15 }}
              className={classes.heading}
            >
              ==> Use "{` {name} `}" for ambassadors name
            </Typography>
            <Typography
              style={{ paddingBottom: 15 }}
              className={classes.heading}
            >
              ==> Use "{` {company} `}" for company/business name
            </Typography>
            <Typography
              style={{ paddingBottom: 15 }}
              className={classes.heading}
            >
              ==> Use "{` {promocode} `}" for the promocode that is being used
            </Typography>
            <Typography
              style={{ paddingBottom: 15 }}
              className={classes.heading}
            >
              ==> Use "{` {promocodeDiscount} `}" for the amount of promocode
              that is being used
            </Typography>
            <Typography
              style={{ paddingBottom: 15 }}
              className={classes.heading}
            >
              ==> Use "{` {usageCountForPrize} `}" for the count of usage needed
              to get a prize
            </Typography>
            <Typography
              style={{ paddingBottom: 15 }}
              className={classes.heading}
            >
              ==> Use "{` {discountType} `}" for the type of discount that is
              being offered in the prize
            </Typography>
            <Typography
              style={{ paddingBottom: 15 }}
              className={classes.heading}
            >
              ==> Use "{` {discountValue} `}" for the amount of discount that is
              being offered in the prize
            </Typography>
            <Typography
              style={{ paddingBottom: 15 }}
              className={classes.heading}
            >
              ==> Use "{` {usageCountLeftForPrize} `}" for the count of usage
              left to claim a prize
            </Typography>
            <Typography
              style={{ paddingBottom: 15 }}
              className={classes.heading}
            >
              ==> Use "{` {prizeCoupon} `}" used in the final email only, to
              send the prize code to the ambasador
            </Typography>
            <Typography
              style={{ paddingBottom: 15 }}
              className={classes.heading}
            >
              ==> Use "{` {usageCount} `}" to show the number of times the
              promocode has been used before getting the prize
            </Typography>

            <Divider />
            <center>
              <Typography className={classes.heading}>
                <b>Ambassador Acceptance Email</b>
              </Typography>
            </center>
            <center>
              <Typography className={classes.secondaryHeading}>
                Edit template of the email ambassador initially gets
              </Typography>
            </center>

            <ReactQuill
              theme="snow"
              value={tempAmbassador}
              onChange={setTempAmbassador}
            />
          </div>

          <div style={{ paddingBottom: 20 }}>
            <Divider />
            <center>
              <Typography className={classes.heading}>
                <b>Code Usage Count 1 Email</b>
              </Typography>
            </center>
            <center>
              <Typography className={classes.secondaryHeading}>
                Edit template of the email when the promocode is used 1 time
              </Typography>
            </center>

            <ReactQuill theme="snow" value={temp1} onChange={setTemp1} />
          </div>

          <div style={{ paddingBottom: 20 }}>
            <Divider />
            <center>
              <Typography className={classes.heading}>
                <b>Code Usage Count 2 Email</b>
              </Typography>
            </center>
            <center>
              <Typography className={classes.secondaryHeading}>
                Edit template of the email when the promocode is used 2 times
              </Typography>
            </center>

            <ReactQuill theme="snow" value={temp2} onChange={setTemp2} />
          </div>

          <div style={{ paddingBottom: 20 }}>
            <Divider />
            <center>
              <Typography className={classes.heading}>
                <b>Code Usage Count 3 Email</b>
              </Typography>
            </center>
            <center>
              <Typography className={classes.secondaryHeading}>
                Edit template of the email when the promocode is used 3 times
              </Typography>
            </center>

            <ReactQuill theme="snow" value={temp3} onChange={setTemp3} />
          </div>

          <div style={{ paddingBottom: 20 }}>
            <Divider />
            <center>
              <Typography className={classes.heading}>
                <b>Code Usage Count 4 Email</b>
              </Typography>
            </center>
            <center>
              <Typography className={classes.secondaryHeading}>
                Edit template of the email when the promocode is used 4 times
              </Typography>
            </center>

            <ReactQuill theme="snow" value={temp4} onChange={setTemp4} />
          </div>

          <div style={{ paddingBottom: 20 }}>
            <Divider />
            <center>
              <Typography className={classes.heading}>
                <b>Code Usage Count 5 Email</b>
              </Typography>
            </center>
            <center>
              <Typography className={classes.secondaryHeading}>
                Edit template of the email when the promocode is used 5 times
              </Typography>
            </center>

            <ReactQuill theme="snow" value={temp5} onChange={setTemp5} />
          </div>

          <div style={{ paddingBottom: 20 }}>
            <Divider />
            <center>
              <Typography className={classes.heading}>
                <b>Code Usage Count 6 Email</b>
              </Typography>
            </center>
            <center>
              <Typography className={classes.secondaryHeading}>
                Edit template of the email when the promocode is used 6 times
              </Typography>
            </center>

            <ReactQuill theme="snow" value={temp6} onChange={setTemp6} />
          </div>

          <div style={{ paddingBottom: 20 }}>
            <Divider />
            <center>
              <Typography className={classes.heading}>
                <b>Code Usage Count 7 Email</b>
              </Typography>
            </center>
            <center>
              <Typography className={classes.secondaryHeading}>
                Edit template of the email when the promocode is used 7 times
              </Typography>
            </center>

            <ReactQuill theme="snow" value={temp7} onChange={setTemp7} />
          </div>

          <div style={{ paddingBottom: 20 }}>
            <Divider />
            <center>
              <Typography className={classes.heading}>
                <b>Code Usage Count 8 Email</b>
              </Typography>
            </center>
            <center>
              <Typography className={classes.secondaryHeading}>
                Edit template of the email when the promocode is used 8 times
              </Typography>
            </center>

            <ReactQuill theme="snow" value={temp8} onChange={setTemp8} />
          </div>

          <div style={{ paddingBottom: 20 }}>
            <Divider />
            <center>
              <Typography className={classes.heading}>
                <b>Code Usage Count 9 Email</b>
              </Typography>
            </center>
            <center>
              <Typography className={classes.secondaryHeading}>
                Edit template of the email when the promocode is used 9 times
              </Typography>
            </center>

            <ReactQuill theme="snow" value={temp9} onChange={setTemp9} />
          </div>

          <div style={{ paddingBottom: 20 }}>
            <Divider />
            <center>
              <Typography className={classes.heading}>
                <b>Final Code Usage Email</b>
              </Typography>
            </center>
            <center>
              <Typography className={classes.secondaryHeading}>
                Edit template of the email when the promocode is used for the
                final time
              </Typography>
            </center>

            <ReactQuill
              theme="snow"
              value={tempFinal}
              onChange={setTempFinal}
            />
          </div>

          <div style={{ paddingBottom: 20 }}>
            <Divider />
            <center>
              <Typography className={classes.heading}>
                <b>Code Usage Count more than 10 Email</b>
              </Typography>
            </center>
            <center>
              <Typography className={classes.secondaryHeading}>
                Edit template of the email when the promocode is used more than
                10 time
              </Typography>
            </center>

            <ReactQuill
              theme="snow"
              value={tempOthers}
              onChange={setTempOthers}
            />
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              color="default"
              size="large"
              className={classes.button}
              startIcon={<SaveIcon />}
              onClick={updateTemplatesBtn}
            >
              Save
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
}
